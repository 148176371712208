.markered-list__items {
  @include list-reset;
}

.markered-list__item {
  padding-left: 60px;
  position: relative;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 24px;
    height: 27px;
    background: url("../img/svg/list-marker.svg") no-repeat;
    background-size: 100% 100%;

    transform: scale(0);

    .sal-animate & {
      animation: scale 1s forwards;
    }
  }

  @include vp--767 {
    padding-left: 40px;
  }

  .line-wrap {
    --delay: 0.1s;
  }
}

.markered-list__title {
  margin: 0 auto 70px;
  max-width: 520px;
  color: $color-red;

  @include vp--1279 {
    margin: 0;
    margin-bottom: 35px;
  }
}

.markered-list--2-columns {
  .markered-list__items {
    columns: 2;
    column-gap: 55px;

    margin-bottom: -25px;

    @include vp--1279 {
      columns: auto;
    }
  }

  .markered-list__item {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

.markered-list--white {
  .markered-list__item {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      width: 23px;
      height: 28px;
      background-image: url("../img/svg/list-marker-white.svg");
    }

    @include vp--767 {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        width: 18px;
        height: 22px;
      }
    }
  }
}
