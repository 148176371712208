.feedback {
  background: $color-black-light;
  color: $color-default-white;
}

.feedback__head {
  padding-top: 166px;
  padding-bottom: 138px;

  border-bottom: 2px solid rgba($color-default-white, 0.1);

  @include vp--1439 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include vp--1279 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

.feedback__title {
  max-width: 1400px;

  span {
    color: $color-blue;
  }
}

.feedback__content {
  display: flex;
  max-width: 1400px;

  @include vp--1023 {
    display: block;
  }
}

.feedback__form {
  width: 50%;
  border-right: 2px solid rgba($color-default-white, 0.1);
  padding-top: 150px;
  padding-right: 175px;
  padding-bottom: 150px;

  @include vp--1439 {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-right: 100px;
  }

  @include vp--1279 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include vp--1023 {
    width: auto;
    border: none;
    padding-right: 0;
    border-bottom: 2px solid rgba($color-default-white, 0.1);
  }
}

.feedback__fields {
  margin-bottom: 60px;
}

.feedback__field {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }
}

.feedback__personal-data {
  margin-bottom: 100px;
}

.feedback__contacts {
  width: 50%;
  padding-top: 150px;
  padding-left: 135px;
  padding-bottom: 150px;

  display: flex;
  flex-direction: column;

  @include vp--1279 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include vp--1023 {
    width: auto;
    padding: 70px 0;
  }
}

.feedback__contacts-list {
  @include list-reset;

  margin-top: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feedback__contacts-link {
  color: $color-blue;

  display: flex;
  align-items: center;

  svg {
    display: block;
    width: 36px;
    height: 37px;
    margin-right: 20px;
    fill: currentColor;
    stroke: none;
  }

  &:hover {
    color: #1F5799;
  }

  &:active {
    color: #194880;
  }

  &:focus {
    color: $color-default-white;

    svg {
      fill: $color-blue;
      stroke: $color-default-white;
    }
  }
}

.feedback__box {
  margin-bottom: 30px;
}

.form-message {
  margin-top: 30px;
  color: $color-red;

  a {
    color: inherit;
  }

  &--hidden {
    display: none;
  }
}
