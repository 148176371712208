// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";
@import "vendor/splitting";

// Global
//---------------------------------

@import "variables";
@import "mixins";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";
@import "global/typography";
@import "global/animations";

// Blocks
//---------------------------------
@import "blocks/numbered-list";
@import "blocks/markered-list";
@import "blocks/field-text";
@import "blocks/field-checkbox";
@import "blocks/button";

@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/intro";
@import "blocks/about";
@import "blocks/contacts";
@import "blocks/certificate";
@import "blocks/page-content";
@import "blocks/digital";
@import "blocks/technologies";
@import "blocks/important";
@import "blocks/certification-how";
@import "blocks/certification-who";
@import "blocks/publications";
@import "blocks/publication-card";
@import "blocks/membership";
@import "blocks/feedback";
@import "blocks/box-animation";

// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
