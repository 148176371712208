.header {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  background: $color-default-white;
  transition: transform $trans--600;

  &--hidden {
    transform: translateY(-100%);
  }
}

.header__container {
  display: flex;
  align-items: center;

  padding-top: 30px;
  padding-bottom: 30px;

  @include vp--767 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.header__logo {
  width: 273px;

  @include vp--767 {
    width: 140px;
  }
}

.header__contacts {
  margin-left: auto;

  @include vp--1279 {
    max-width: 60%;
  }
}
