.page-content__sticky-container {
  padding-top: 360px;
  position: relative;

  @include vp--1279 {
    padding-top: 250px;
  }

  @include vp--1023 {
    padding-top: 150px;
  }

  @include vp--767 {
    padding-top: 50px;
  }
}

.page-content__logo-sticky {
  position: sticky;
  z-index: 1;
  top: 205px;
  pointer-events: none;

  img {
    margin: 0 auto;
  }
}

.page-content__sticky-wrapper {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 100px;
  left: 0;
  pointer-events: none;

  &--right {
    z-index: 2;
    top: 700px;
    right: 100px;

    img {
      margin-right: 0;
    }

    @include vp--767 {
      display: none;
    }
  }
}

.page-content__section {
  margin-bottom: 275px;

  @include vp--1279 {
    margin-bottom: 250px;
  }

  @include vp--1023 {
    margin-bottom: 150px;
  }

  @include vp--767 {
    margin-bottom: 50px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
