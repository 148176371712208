.publications {
  overflow: hidden;
}

.publications__container {
  max-width: 1530px;
  background: $color-gray;
  padding: 112px 107px 120px;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-gray-light;
    transform: scale(0, 1);
    transform-origin: left center;

    .sal-animate & {
      animation: slideHorizontal 1s forwards;
    }
  }

  @include vp--767 {
    padding: 60px 24px;
  }
}

.publications__title {
  margin: 0 0 90px;
  color: $color-blue;

  @include vp--1279 {
    margin-bottom: 60px;
  }

  @include vp--767 {
    margin-bottom: 40px;
  }
}

.publications__slider {
  overflow: visible;

  will-change: transform;
}

.publications__slider-inner {
  min-height: 730px;

  @include vp--767 {
    min-height: 500px;
  }
}

.publications__slide {
  max-width: 445px;
  margin-right: 125px;
  height: auto;

  &--top {
    align-self: flex-start;
  }

  &--center {
    align-self: center;
  }

  &--bottom {
    align-self: flex-end;
  }

  @include vp--1439 {
    margin-right: 75px;
  }

  @include vp--1279 {
    max-width: 400px;
    margin-right: 50px;
  }
}

.publications__back {
  position: absolute;
  z-index: -1;
  width: 100%;
  max-width: 799px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
