.field-checkbox {
  .pristine-error {
    top: 100%;
    transform: none;
  }
}

.field-checkbox__label {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;

  font-size: 18px;
  line-height: 22px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $color-default-white;
  }

  &::after {
    background: url("../img/svg/check.svg") no-repeat center;
    background-size: 15px 10px;
    opacity: 0;
    transition: opacity $trans--300;
  }

  input[type="checkbox"]:checked + & {
    &::after {
      opacity: 1;
    }
  }

  input[type="checkbox"]:focus + & {
    &::before {
      box-shadow: 0 0 0 2px $color-blue;
    }
  }
}

.field-text--error {
  input[type="checkbox"]:focus + .field-checkbox__label::before,
  .field-checkbox__label::before {
    box-shadow: 0 0 0 2px $color-red;
  }
}
