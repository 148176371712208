.field-text {
  position: relative;
  z-index: 1;
}

.field-text__input {
  display: block;
  width: 100%;
  padding: 20px 0;
  border: none;
  border-bottom: 1px solid rgba($color-default-white, 0.5);
  background: none;
  appearance: none;

  font-size: inherit;
  line-height: 1;

  color: $color-default-white;
  outline: none;

  &:focus,
  .field-text--filled &  {
    border-bottom-color: $color-default-white;
  }

  .field-text--error & {
    border-bottom-color: $color-red-dark;
    padding-right: 250px;

    @include vp--767 {
      padding-right: 190px;
    }
  }
}

.field-text__label {
  position: absolute;
  padding: 20px 0 24px;
  background: $color-black-light;
  left: 0;
  top: 0;
  font-size: inherit;
  line-height: 1;

  input:focus + &,
  .field-text--filled & {
    color: $color-almost-white;
    font-size: 16px;
    background: transparent;
    padding: 0;
  }

  @include vp--767 {
    padding: 20px 0;
  }
}

.field-text__error {
  color: $color-red-dark;
  background: $color-black-light;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  font-size: 18px;
  line-height: 1;
  padding: 10px;
  padding-right: 0;

  @include vp--767 {
    font-size: 14px;
  }
}
