.about__container {
  padding-top: 175px;
  padding-bottom: 190px;
  max-width: 1170px;
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));

  @include vp--1023 {
    align-items: flex-start;
    max-width: 640px;
    margin: 0 auto;
  }
}

.about__title {
  margin: 0;
  margin-bottom: 75px;
  color: $color-blue;

  @include vp--1023 {
    margin-bottom: 45px;
  }

  @include vp--767 {
    margin-bottom: 25px;
  }
}

.about__text {
  max-width: 640px;
  align-self: flex-end;
  margin: 0;

  .line-wrap {
    --delayStart: 0.75s;
  }

  @include vp--1023 {
    align-self: stretch;
  }
}

.about__image {
  position: absolute;
  z-index: -1;
  left: 40px;
  top: 37%;

  max-width: 60%;

  img {
    .sal-animate & {
      animation: moveUpDown 3s infinite alternate ease-in-out;
    }
  }

  @include vp--1023 {
    max-width: 80%;
    left: -90px;
  }

  @include vp--767 {
    left: 10px;
  }
}
