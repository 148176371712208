.contacts__list {
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: -10px;
  margin-left: -50px;

  @include vp--1439 {
    margin-left: -25px;
  }

  @include vp--1279 {
    flex-wrap: wrap;
  }

  @include vp--767 {
    margin-left: -12px;
  }
}

.contacts__item {
  margin-top: 10px;
  margin-left: 50px;

  @include vp--1439 {
    margin-left: 25px;
  }

  @include vp--767 {
    margin-left: 12px;
  }
}

.contacts__link {
  display: flex;
  align-items: center;
  color: #2D264B;
  transition: color $trans--300;

  @include vp--767 {
    font-size: 0;
  }

  &:link {
    &:hover {
      color: #1F5799;
    }

    &:active {
      color: #194880;
    }
  }

  svg {
    display: block;
    width: 33px;
    height: 33px;

    margin-right: 15px;
  }

  @include vp--1279 {
    svg {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }

  @include vp--767 {
    svg {
      width: 24px;
      height: 24px;
      margin-right: 0;
    }
  }
}

.contacts__toggle {
  display: none;
}
