.digital {

}

.digital__container {
  max-width: 1530px;
  position: relative;

  color: $color-almost-black;
}

.digital__icon {
  position: absolute;
  z-index: 2;
  left: 71%;
  bottom: 20%;
  width: 90%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  @include vp--1919 {
    width: 80%;
  }

  @include vp--1439 {
    width: 65%;
  }

  @include vp--1279 {
    left: 82%;
  }

  @include vp--767 {
    position: static;
    left: auto;
    bottom: auto;

    width: 80%;
    margin: 0 auto 35px;
  }
}

.digital__content {
  border: 9px solid $color-orange;
  background: $color-default-white;
  padding: 135px 69px 275px;
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: 900px;

  @include vp--1279 {
    width: 70%;
    max-width: 100%;
    padding: 100px 30px 150px;
  }

  @include vp--767 {
    width: 100%;

    padding: 60px 20px;
    border-width: 5px;
  }
}

.digital__title {
  margin: 0 0 90px;
  display: flex;
  flex-direction: column;
  color: $color-orange;

  @include vp--1279 {
    margin-bottom: 60px;
  }

  @include vp--1023 {
    margin-bottom: 35px;
  }
}

.digital__subtitle,
.digital__title .line-wrap:last-child {
  display: block;
  margin-left: 105px;
  color: $color-default-black;

  @include vp--1023 {
    margin-left: 80px;
  }

  @include vp--767 {
    margin-left: 0;
  }
}

.digital__text {
  max-width: 70%;

  .line-wrap {
    --delayStart: 0.7s;
  }

  @include vp--1279 {
    max-width: 80%;
  }

  @include vp--1023 {
    max-width: 85%;
  }

  @include vp--767 {
    max-width: 100%;
  }
}
