.certification-who {
  overflow: hidden;

  @include vp--1279 {
    padding: 0 50px;
  }

  @include vp--1023 {
    padding: 0 30px;
  }

  @include vp--767 {
    padding: 0;
  }
}

.certification-who__container {
  background: $color-blue-light;
  max-width: 1330px;
  color: $color-default-white;

  padding: 145px 100px;

  position: relative;
  z-index: 1;

  @include vp--1023 {
    padding: 100px 24px;
  }
}

.certification-who__title {
  margin: 0 0 170px;

  @include vp--1023 {
    margin-bottom: 100px;
  }
}

.certification-who__lists {
  display: flex;
  flex-wrap: wrap;
  padding-left: 100px;

  margin-bottom: -45px;

  @include vp--1279 {
    padding-left: 0;
  }

  @include vp--767 {
    flex-direction: column;
  }
}

.certification-who__list {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 45px;

  &:first-child {
    .markered-list {
      max-width: 385px;
    }
  }

  &:nth-child(2) {
    width: 50%;
    margin-top: 255px;
  }

  &:nth-child(3) {
    .markered-list {
      max-width: 520px;
    }
  }

  @include vp--767 {
    &:nth-child(n) {
      width: 100%;
      margin: 0;

      margin-bottom: 45px;

      &:last-child {
        margin-bottom: 0;
      }

      .markered-list {
        max-width: 100%;
      }
    }
  }
}

.certification-who__list-title {
  margin: 0 0 30px;
  position: relative;
  z-index: 1;
  padding-left: 60px;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 220px;
    height: 30px;
    background: url("../img/svg/arrow-long-white.svg") no-repeat;
    left: -262px;
    top: 50%;
    transform: translateY(-50%);
  }

  .certification-who__list:nth-child(2) & {
    align-self: flex-start;

    &::after {
      transform: translateY(-50%) rotate(180deg);
      left: auto;
      right: -262px;
    }
  }

  @include vp--1279 {
    &::after {
      left: -175px;
    }
  }

  @include vp--767 {
    padding-left: 40px;

    &::after {
      left: -190px;
    }

    .certification-who__list:nth-child(2) & {
      &::after {
        transform: translateY(-50%);
        left: -190px;
        right: auto;
      }
    }
  }
}

.certification-who__icon {
  position: absolute;
  z-index: -1;

  svg {
    position: absolute;

    .sal-animate & {
      animation: moveUpDownSmall 3s infinite alternate ease-in-out;
    }
  }

  @include vp--767 {
    opacity: 0.6;
  }

  &--triangle {
    top: 0;
    right: 0;
    width: 39%;
    height: 41%;
    background: url("../img/svg/triangle-bg.svg") no-repeat;
    background-position: right top;
    background-size: contain;

    svg {
      top: 7%;
      right: -20%;
      width: 83%;
      height: auto;
    }

    @include vp--1279 {
      svg {
        top: 0;
      }
    }
  }

  &--squares {
    bottom: 340px;
    left: 0;
    width: 32%;
    height: 50%;
    background: $color-blue;

    svg {
      width: 80%;
      height: auto;
      left: -40%;
      top: 50%;
      transform: translateY(-50%);
    }

    @include vp--1279 {
      bottom: 300px;
    }
  }

  &--pentagons {
    bottom: 0;
    right: 0;
    width: 49%;
    height: 48%;
    background: url("../img/svg/pentagons-bg.svg") no-repeat;
    background-size: contain;
    background-position: right bottom;

    svg {
      width: 56%;
      height: auto;
      right: -17%;
      bottom: 10%;
    }
  }
}
