.btn {
  padding: 26px 45px 20px;
  border: none;
  background: $color-blue;
  color: $color-default-white;
  margin: 0;
  outline: none;
  user-select: none;
  cursor: pointer;

  font-family: $font-family--secondary;
  font-weight: bold;
  font-size: 48px;
  line-height: 1;
  transition: background-color $trans--300, box-shadow $trans--300;

  &:hover {
    background-color: #1F5799;
  }

  &:active {
    background-color: #194880;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px $color-default-white;
  }

  &:disabled {
    color: #A7A7A7;
    background-color: #53575C;
  }

  @include vp--1023 {
    padding: 20px 30px;
    font-size: 30px;
  }
}
