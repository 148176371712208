.intro {
  position: relative;
  background: #020811;
  height: 100vh;
  /* stylelint-disable-next-line */
  height: calc(100 * var(--vh, 1vh));
}

.intro__logo {
  max-width: 60%;

  animation: pulse 2s infinite;

  @include vp--767 {
    max-width: 90%;
  }
}

.intro__btn {
  position: absolute;
  z-index: 1;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);

  width: 52px;
  height: 50px;

  cursor: pointer;

  opacity: 0;

  animation: fadeIn 1s forwards, moveUpDownArrow 1s infinite alternate ease-in-out;
  animation-delay: 7.5s, 8.5s;

  svg {
    display: block;
    width: 100%;
    height: 100%;
    fill: none;
  }

  @include vp--1439 {
    width: 35px;
    height: 33px;
  }
}
