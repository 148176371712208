.important {
  max-width: 1600px;
  position: relative;
  z-index: 1;
}

.important__icon {
  width: 53%;
  position: absolute;
  z-index: 1;
  top: 40px;
  right: -32.5%;

  .sal-animate & {
    animation: moveUpDownSmall 3s infinite alternate ease-in-out;
  }

  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  @include vp--1919 {
    width: 50%;
    right: -30%;
  }

  @include vp--1439 {
    width: 45%;
    right: -25%;
  }

  @include vp--767 {
    position: relative;
    top: auto;
    right: auto;
    width: 80%;
    margin: 0 auto 35px;
  }
}

.important__content {
  background: $color-gray;
  padding: 110px;
  padding-right: 120px;

  max-width: 1055px;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 50%;
    padding-bottom: 59%;
    background: $color-gray-light;
    right: 0;
    top: 0;
    clip-path: polygon(20% 0, 100% 0, 100% 100%, 30% 100%, 0% 35%);
  }

  @include vp--1439 {
    width: 80%;
  }

  @include vp--1279 {
    padding: 100px 40px;
  }

  @include vp--1023 {
    padding: 100px 30px;
  }

  @include vp--767 {
    width: 100%;

    padding: 60px 20px;
  }
}

.important__title {
  margin: 0 0 75px;

  @include vp--1279 {
    margin-bottom: 35px;
  }
}
