@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes revealText {
  from { opacity: 0; transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0); }
  to   { opacity: 1; transform: translate(0%, 0%) matrix(1, 0, 0, 1, 0, 0); }
}

@keyframes moveUpDown {
  0% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(20%);
  }
}

@keyframes moveUpDownSmall {
  0% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(10%);
  }
}

@keyframes moveUpDownArrow {
  0% {
    transform: translateX(-50%) translateY(0);
  }

  100% {
    transform: translateX(-50%) translateY(50%);
  }
}


@keyframes slideDown {
  0% {
    transform: scale(1, 0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes slideHorizontal {
  0% {
    transform: scale(0, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@mixin setSlideDown {
  transform: scale(1, 0);
  transform-origin: center top;

  .numbered-list.sal-animate & {
    animation: slideDown 1s forwards;
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}


.line-wrap {
  display: block;
  position: relative;
  text-align: start;
  overflow: hidden;

  --delayStart: 0s;
  --duration: 0.5s;
  --delay: 0.25s;

  span {
    display: inline-block;
    opacity: 0;
    transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
    will-change: opacity, transform;

    .sal-animate & {
      animation: revealText 0.7s cubic-bezier(0.7, 0, 0.3, 1) forwards;
      animation-delay: calc(var(--delayStart) + var(--delay) * var(--line-index));
    }
  }
}
