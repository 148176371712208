@mixin h1 {
  font-size: 120px;
  line-height: 0.9;
  font-family: $font-family--secondary;

  @include vp--1023 {
    font-size: 90px;
  }

  @include vp--767 {
    font-size: 60px;
  }
}

@mixin h2 {
  font-size: 60px;
  line-height: 0.9;
  font-family: $font-family--secondary;

  @include vp--1279 {
    font-size: 50px;
  }

  @include vp--1023 {
    font-size: 40px;
  }
}

@mixin h3 {
  font-size: 30px;
  line-height: 1.2;
  font-family: $font-family--secondary;

  @include vp--1279 {
    font-size: 26px;
  }

  @include vp--1023 {
    font-size: 22px;
  }
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}
