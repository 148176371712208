.publication-card {
  font-size: 20px;
  line-height: 1.5;

  @include vp--1279 {
    font-size: 18px;
  }
}

.publication-card__image {
  display: block;
  margin-bottom: 30px;

  @include vp--1279 {
    margin-bottom: 20px;
  }
}

.publication-card__title {
  font-size: 20px;
  line-height: 1.2;
  font-family: $font-family--primary;
  margin: 0 0 25px;
  padding-right: 40px;

  @include vp--1279 {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.publication-card__text {
  margin: 0 0 50px;

  @include vp--1279 {
    margin-bottom: 30px;
  }
}

.publication-card__link {
  color: $color-blue;
  font-weight: bold;
  transition: color $trans--300;
  font-size: 18px;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-blue-lighter;
    }
  }
}
