.technologies {
  max-width: 1400px;
  position: relative;
  z-index: 1;
}

.technologies__content {
  background: $color-blue-darker;
  background-size: auto 100%;
  background-position: left center;
  color: $color-default-white;

  padding: 130px 115px 190px;
  padding-right: 85px;
  max-width: 1100px;
  margin-left: auto;

  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background: $color-blue-dark;
  }

  @include vp--1439 {
    width: 85%;
  }

  @include vp--1279 {
    padding: 100px 50px;
  }

  @include vp--1023 {
    padding: 100px 30px;

    &::before {
      width: 180px;
    }
  }

  @include vp--767 {
    width: 100%;

    padding: 60px 20px;

    &::before {
      width: 100px;
    }
  }

}

.technologies__title {
  margin: 0 0 175px;

  @include vp--1279 {
    margin-bottom: 125px;
  }

  @include vp--1023 {
    margin-bottom: 80px;
  }

  @include vp--767 {
    margin-bottom: 35px;
  }
}

.technologies__subtitle {
  color: $color-blue-lighter;
}

.technologies__text {
  max-width: 430px;
  margin-left: auto;
  margin-right: 40px;

  line-height: 1.5;

  margin-bottom: 215px;

  .line-wrap span {
    --delay: 0.1s;
  }

  @include vp--1279 {
    max-width: 350px;
    margin-right: 0;
  }

  @include vp--1023 {
    max-width: 310px;
    margin-bottom: 150px;
  }

  @include vp--767 {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
  }
}

.technologies__timeline {
  display: flex;

  .line-wrap span {
    --delay: 0.1s;
  }

  @include vp--767 {
    flex-direction: column;
  }
}

.technologies__item {
  max-width: 310px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  &:last-child {
    max-width: 385px;
    margin-right: 0;
    margin-left: 50px;

    .technologies__item-title::after {
      display: none;
    }

    .line-wrap {
      --delayStart: 0.6s;
    }
  }

  @include vp--1279 {
    &:last-child {
      max-width: 310px;
    }
  }

  @include vp--767 {
    margin-bottom: 100px;

    &:last-child {
      margin: 0;
    }
  }
}

.technologies__item-title {
  margin: 0 0 30px;
  color: $color-blue-lighter;

  position: relative;
  z-index: 1;
  align-self: flex-start;

  line-height: 1.1;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: calc(100% + 45px);
    top: 50%;
    transform: translateY(-50%);
    width: 276px;
    height: 31px;
    fill: none;
    background: url("../img/svg/arrow-right-long.svg") no-repeat;
    background-size: 276px 31px;
    background-position: right center;
  }

  @include vp--1439 {
    &::after {
      width: 200px;
    }
  }

  @include vp--1023 {
    &::after {
      left: calc(100% + 15px);
      width: 120px;
    }
  }

  @include vp--767 {
    position: static;

    &::after {
      top: calc(100% + 30px);
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      width: 70px;
    }
  }
}

.technologies__item-text {
  margin: 0;
}

.technologies__icon {
  position: absolute;
  z-index: -1;
  width: 58%;
  top: 21%;
  left: -18%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 50%;
    height: 120%;
    border-radius: 0 540px 540px 0 ;
    top: -10%;
    right: -5%;
    background: $color-blue-dark;
  }

  @include vp--1023 {
    width: 50%;
    left: -15%;
    top: 22%;
  }

  @include vp--767 {
    position: relative;
    left: auto;
    top: auto;
    margin: 0 auto 35px;
    width: 80%;

    &::after {
      display: none;
    }
  }
}
