.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include vp--1919 {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include vp--1439 {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include vp--1023 {
    padding-left: 24px;
    padding-right: 24px;
  }

  @include vp--767 {
    padding-left: 16px;
    padding-right: 16px;
  }

  &--no-padding {
    @include vp--767 {
      padding: 0;
    }
  }
}
