.numbered-list__items {
  @include list-reset;
  counter-reset: numbered-list;
}

.numbered-list__item {
  counter-increment: numbered-list;
  padding-left: 108px;
  position: relative;
  margin-bottom: 28px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    @include h2;
    content: counter(numbered-list, decimal-leading-zero);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    font-weight: 400;
    display: block;
    opacity: 0;
    transition: opacity $trans--600;
    transition-delay: calc(var(--itemStartDelay) + 0.5s);

    .sal-animate & {
      opacity: 1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 10px;
    bottom: 10px;
    left: 72px;
    background: $color-default-black;
    width: 1px;

    @include setSlideDown;

    .numbered-list.sal-animate & {
      animation-delay: var(--itemStartDelay);
    }
  }

  @include vp--1023 {
    padding-left: 80px;

    &::after {
      left: 50px;
    }
  }

  @include vp--767 {
    padding-left: 0;

    &::before {
      position: static;
      margin-bottom: 10px;
    }

    &::after {
      display: none;
    }
  }

  .line-wrap {
    --delayStart: calc(var(--itemStartDelay) + 0.5s);
    --delay: 0.1s;
  }
}

.numbered-list__title {
  margin: 0 0 75px;
  padding-left: 108px;
  color: $color-orange;

  @include vp--1023 {
    padding-left: 80px;
    margin-bottom: 35px;
  }

  @include vp--767 {
    padding-left: 0;
  }
}

.numbered-list--lines {
  .numbered-list__items {
    display: flex;
    flex-wrap: wrap;
  }

  .numbered-list__item {
    max-width: 370px;
    padding: 25px 30px 34px;
    padding-right: 24px;
    margin-bottom: 0;
    letter-spacing: -0.05em;
    line-height: 1.15;

    --itemStartDelay: 0s;

    &::before {
      position: static;
      display: block;
      color: $color-purple-darker;
      margin-bottom: 18px;
    }

    &::after {
      top: 0;
      left: 0;
      bottom: auto;
      width: 52px;
      height: 2px;
      background: #808080;
      transform: scale(0, 1);
      transform-origin: right center;

      @include vp--1023 {
        height: 1px;
      }
    }

    &:first-child,
    &:nth-child(6) {
      width: 33.89%;
    }

    &:nth-child(2),
    &:nth-child(5),
    &:nth-child(7) {
      width: 36.84%;
    }

    &:nth-child(3),
    &:nth-child(8) {
      width: 25.26%;
    }

    &:nth-child(4) {
      width: 34.42%;
      margin-left: 16.75%;
    }

    &:nth-child(4),
    &:nth-child(5) {
      &::after {
        display: none;
      }

      .numbered-list__item-line {
        top: 6px;
      }
    }

    &:nth-child(2) {
      .numbered-list__item-lines::before {
        width: calc(50% - 30px);
      }

      .numbered-list__item-lines::after {
        left: calc(50% - 5px);
      }

      .numbered-list__item-decor::after {
        left: calc(50% - 21px);
      }
    }

    &:nth-child(3) {
      .numbered-list__item-lines::before {
        width: calc(100% - 90px);
      }

      .numbered-list__item-lines::after {
        left: calc(100% - 65px);
        right: 20px;
      }

      .numbered-list__item-decor::after {
        left: calc(100% - 81px);
      }
    }

    &:nth-child(5) {
      width: 375px;

      .numbered-list__item-lines::before {
        width: calc(50% - 12px);
      }

      .numbered-list__item-lines::after {
        left: calc(50% + 14px);
      }

      .numbered-list__item-decor::after {
        display: none;
      }
    }

    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      &::after {
        top: auto;
        bottom: 0;
      }

      .numbered-list__item-lines {
        &::before {
          top: -2px;
          bottom: auto;
        }

        &::before,
        &::after {
          transform-origin: right center;
        }
      }

      .numbered-list__item-decor::before {
        top: -9px;
        bottom: auto;
      }

      .numbered-list__item-line {
        top: 10px;
        bottom: 0;
      }

      .numbered-list__item-decor::after,
      .numbered-list__item-lines::after {
        display: none;
      }
    }

    &:nth-child(7) {
      .numbered-list__item-decor::before {
        display: none;
      }

      .numbered-list__item-lines::before {
        left: calc(50% + 12px);
      }

      .numbered-list__item-line {
        left: -1px;
      }
    }

    &:nth-child(8) {
      .numbered-list__item-decor::before {
        left: calc(50% + 42px);
        background-color: $color-default-white;
      }

      .numbered-list__item-lines::before {
        width: calc(100% - 25px);
      }

      .numbered-list__item-decor::after {
        display: block;
        left: -8px;
        bottom: auto;
        top: -10px;
        background-color: $color-default-white;
      }
    }

    &:nth-child(2) {
      --itemStartDelay: 0.5s;
    }

    &:nth-child(3) {
      --itemStartDelay: 1s;
    }

    &:nth-child(4) {
      --itemStartDelay: 1.5s;
    }

    &:nth-child(5) {
      --itemStartDelay: 2s;
    }

    &:nth-child(6) {
      --itemStartDelay: 2.5s;
    }

    &:nth-child(7) {
      --itemStartDelay: 3s;
    }

    &:nth-child(8) {
      --itemStartDelay: 3.5s;
    }

    @include vp--1023 {
      border: 1px solid #808080;
      border-top: none;
      border-right: none;

      &:nth-child(n) {
        width: 50%;
        margin: 0;

        .numbered-list__item-decor {
          &::before {
            left: -9px;
          }

          &::after {
            left: auto;
            right: -10px;
          }
        }
      }

      &:nth-child(n + 3) {
        &::after {
          display: none;
        }
      }

      &:nth-child(2n) {
        border-right: 1px solid $color-almost-black;

        .numbered-list__item-decor {
          &::before {
            display: none;
          }
        }
      }

      &:nth-child(2) {
        border-right: none;
      }

      &:nth-child(8) {
        .numbered-list__item-decor {
          &::before {
            display: block;
            left: -9px;
          }
        }
      }

      &:nth-last-child(-n+2) {
        border-bottom: none;

        &::after {
          display: block;
        }
      }
    }

    @include vp--767 {
      padding: 20px;

      &:nth-child(n) {
        width: 100%;
        border-right: 1px solid #808080;

        .numbered-list__item-decor {
          &::before,
          &::after {
            display: block;
            top: auto;
            bottom: -10px;
          }
        }
      }

      &:first-child {
        border-right: none;

        &::after {
          display: block;
        }
      }

      &:nth-last-child(2) {
        border-bottom: 1px solid #808080;

        &::after {
          display: none;
        }
      }

      &:last-child {
        border-right: none;

        .numbered-list__item-decor {
          &::before,
          &::after {
            display: none;
          }
        }
      }

      &:nth-child(2) {
        --itemStartDelay: 0.2s;
      }

      &:nth-child(3) {
        --itemStartDelay: 0.4s;
      }

      &:nth-child(4) {
        --itemStartDelay: 0.6s;
      }

      &:nth-child(5) {
        --itemStartDelay: 0.8s;
      }

      &:nth-child(6) {
        --itemStartDelay: 1s;
      }

      &:nth-child(7) {
        --itemStartDelay: 1.2s;
      }

      &:nth-child(8) {
        --itemStartDelay: 1.4s;
      }
    }
  }

  .numbered-list.sal-animate .numbered-list__item::after {
    animation: slideHorizontal 1s forwards;
    animation-delay: var(--itemStartDelay);
  }
}

.numbered-list__item-line {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 10px;
  left: 0;
  width: 2px;
  background: #808080;

  @include setSlideDown;

  .numbered-list.numbered-list--lines.sal-animate & {
    animation-delay: var(--itemStartDelay);
  }

  @include vp--1023 {
    display: none;
  }
}

.numbered-list__item-lines {
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 9px;
    width: calc(50% - 19px);
    background: #808080;
    height: 2px;
    transform-origin: left center;
    transform: scale(0, 1);

    .numbered-list.sal-animate & {
      animation: slideHorizontal 1s forwards;
      animation-delay: var(--itemStartDelay);
    }
  }

  &::after {
    left: calc(50% + 6px);
    width: auto;
    right: 8px;
    transform-origin: right center;
  }

  @include vp--1023 {
    display: none;
  }
}

.numbered-list__item-decor {
  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: -10px;
    left: -8px;

    width: 18px;
    height: 21px;
    background: url("../img/svg/list-marker-black.svg") no-repeat;
    transform: scale(0);

    .numbered-list.sal-animate & {
      animation: scale 1s forwards;
      animation-delay: var(--itemStartDelay);
    }
  }

  &::after {
    left: calc(50% - 10px);
  }

  @include vp--1023 {
    &::before,
    &::after {
      background-color: $color-default-white;
    }
  }
}
