.certificate {

}

.certificate__container {
  max-width: 1345px;
  position: relative;

  color: $color-almost-black;
}

.certificate__icon {
  position: absolute;
  z-index: 2;
  left: -58%;
  top: 26%;
  width: 72%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  @include vp--1279 {
    width: 55%;
    left: -45%;
  }

  @include vp--767 {
    position: relative;
    left: auto;
    top: auto;
    margin: 0 auto 35px;
    width: 80%;
  }
}

.certificate__content {
  border: 9px solid $color-purple;
  background: $color-default-white;
  padding: 155px 40px;
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    background: $color-pink-light;
    left: 0;
    bottom: 0;
    top: 145px;
    width: 46.25%;
    clip-path: polygon(0 0, 100% 25%, 100% 100%, 0 100%);
  }

  @include vp--1439 {
    max-width: 100%;
    width: 60%;
  }

  @include vp--1279 {
    padding: 100px 30px;
  }

  @include vp--1279 {
    width: 70%;
  }

  @include vp--767 {
    width: 100%;

    padding: 60px 20px;
    border-width: 5px;

    &::after {
      width: 75%;
      clip-path: polygon(0 0, 100% 15%, 100% 100%, 0 100%);
    }
  }
}

.certificate__title {
  margin: 0 0 140px;
  display: flex;
  flex-direction: column;

  @include vp--1279 {
    margin-bottom: 100px;
  }

  @include vp--767 {
    margin-bottom: 35px;
  }
}

.certificate__subtitle,
.certificate__title .line-wrap:last-child {
  display: block;
  margin-left: calc(15% + 108px);
  color: $color-pink;

  @include vp--1279 {
    margin-left: calc(15% + 80px);
  }

  @include vp--1023 {
    margin-left: calc(10% + 80px);
  }

  @include vp--767 {
    margin-left: 0;
  }
}

.certificate__text {
  padding-left: 15%;
  max-width: 650px;

  @include vp--1279 {
    padding-left: 10%;
  }

  @include vp--767 {
    padding-left: 0;
  }
}
