.membership {
  max-width: 1435px;
}

.membership__title {
  margin: 0 0 72px;
  color: $color-blue-2;

  @include vp--1279 {
    margin-bottom: 35px;
  }
}

.membership__items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-top: -20px;
  margin-left: -50px;
}

.membership__item {
  margin-left: 50px;
  margin-top: 20px;
  opacity: 0;
  transition: transform $trans--300;

  .sal-animate & {
    animation: fadeIn 1s forwards;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.membership__link {
  display: block;
}
