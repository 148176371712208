.certification-how {
  max-width: 1425px;

  position: relative;
}

.certification-how__content {
  max-width: 1100px;

  padding: 155px 110px;
  padding-right: 40px;
  margin-left: auto;

  position: relative;
  z-index: 1;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 74%;
    height: 63.25%;
    top: 45px;
    left: -1px;
    background: url("../img/svg/eclipse.svg") no-repeat;
    background-size: contain;
  }

  &::after {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 9px solid $color-purple-light;
    z-index: -2;
    width: auto;
    height: auto;
    background: none;
  }

  @include vp--1439 {
    width: 90%;

    padding-left: 40px;
  }

  @include vp--1023 {
    width: 85%;

    padding: 100px 30px;
  }

  @include vp--767 {
    width: 100%;
    padding: 60px 24px;

    &::after {
      border-width: 5px;
    }

    &::before {
      display: none;
    }
  }
}

.certification-how__title {
  margin: 0 0 230px;
  color: $color-purple-darker;

  @include vp--1023 {
    margin-bottom: 150px;
  }

  @include vp--767 {
    margin-bottom: 35px;
  }
}

.certification-how__subtitle {
  margin: 0 0 45px;
  color: $color-purple-dark;
}

.certification-how__text {
  max-width: 450px;
  margin-left: auto;
  margin-bottom: 285px;

  p {
    margin: 0;
  }

  @include vp--1023 {
    max-width: 350px;
  }

  @include vp--767 {
    margin-bottom: 50px;
  }
}

.certification-how__icon {
  position: absolute;
  z-index: 2;
  left: -21%;
  top: 245px;
  width: 57%;

  svg {
    display: block;
    width: 100%;
    height: auto;
  }

  @include vp--1439 {
    width: 44%;
    left: -15%;
  }

  @include vp--767 {
    position: relative;
    left: auto;
    top: auto;
    margin: 0 auto 35px;
    width: 80%;
  }
}

.certification-how__list {
  margin-bottom: 95px;

  @include vp--767 {
    margin-bottom: 50px;
  }
}

.certification-how__more {
  font-weight: 700;
  max-width: 465px;
  margin-left: auto;
  color: $color-purple-dark;
}
